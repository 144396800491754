import React, { useEffect, useState } from 'react';
import {useHistory,useNavigate} from 'react-router-dom';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Scheduleappt.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../Constants/Api';
import $ from "jquery";

const Scheduleappt = () => {
  const [getName, setName] = useState('');
  const [getMobile, setMobile] = useState('');
  const [getEmail, setEmail] = useState('');

  const getApiData = () => {
   
    API.get('get_settings')
    .then(response => {
    
      if (response.data.status == '0') 
      {
          setName('');
          setMobile('');
          setEmail('');
      } 
      else {
        setName(response.data.data.name);
        setMobile(response.data.data.mobile);
        setEmail(response.data.data.appointment_email)
      }
    }).catch(error => {
    });
      
  }

   useEffect(() => {
      document.title = `Scheduleappt | GRUBBRR`;
      document.getElementById('bodywrap').className='scdulaptmain';
      getApiData();
      getApiDataImage();
   },[]);  
 
    const navigate = useNavigate();

    const handleSchedule = () => 
   {
    
    if(getName == "" && getMobile == "" && getEmail == "")
    {
      alert('Detail is required');
    }
    else if(getName == "")
    {
      alert('Name is required');
    }
    else if (getMobile == "")
    {
      alert('Phone Number is required');
    }
    else if (getEmail == "")
    {
      alert('Email is required');
    }
    else
    {
      
      navigate("/selectdate?n=" + getName + "&m="+getMobile+"&e="+getEmail);
        //window.location.href = "confirmationpickup";
    }
      
    
  };

  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }

 
    return (
        <>
        <Navbar sidelink='scheduleappt' headerText={'APPOINTMENT'} headerimg={getImage}/>

        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-start ckeinpg-lftcnt">
                  <div>
                     <h3>Please type in your contact information below to begin scheduling your appointment.</h3>
                  </div>

                  <Form className="">
                     <Form.Group className="mb-3 fltng-inpt" controlId="name">
                       <Form.Control type="name" placeholder="Name" name="name" id="name" onChange={e => setName(e.target.value)} value={getName} required />
                       <Form.Label>Name</Form.Label>
                     </Form.Group>
                     <Form.Group className="mb-3 fltng-inpt" controlId="telphone">
                       <Form.Control type="number" name="telphone" id="telphone" placeholder="Phone Number" onChange={e => setMobile(e.target.value)} value={getMobile} required/>
                       <Form.Label>Phone Number</Form.Label>
                     </Form.Group>
                     <Form.Group className="mb-3 fltng-inpt" controlId="email">
                       <Form.Control type="email" placeholder="Email" id="email" name="email"  value={getEmail} onChange={e => setEmail(e.target.value)} required/>
                       <Form.Label>Email</Form.Label>
                     </Form.Group>
                   </Form>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt">
                  <div className="fst-btn"><a onClick={()=>handleSchedule()}><button type="button" class="btn btn-outline-primary">NEXT</button></a></div>
                  <div className="two-btn"><a href="/"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>
  

        </>
    )
}
export default Scheduleappt;