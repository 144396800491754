import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Confirmationdropoff.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";



const Confirmationdropoff = () => {


   useEffect(() => {
      document.title = `Confirmationdropoff | GRUBBRR`;
      document.getElementById('bodywrap').className='cnfrmdrpofmain';

   },[])  
 


 
    return (
        <>
        <Navbar sidelink='confirmationdropoff' headerText={'CONFIRMATION'} headerimg={'assets/images/logo-blacknav.png'}/>

        <section className="cnfrmtndrpofsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-center cnfmduppg-lftcnt">
                  <div>
                  </div>
                  <div className="specer-pd text-center mb-4"><img src="assets/images/svg/check-img.svg" className="img-fluid" alt="Plus"/></div>
                  <p>Please have a seat,</p>
                  <p>a pharmacist will be with you shortly.</p>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center cnfmduppg-rgtcnt">
                  <div className="mb-md-5 mb-3 tpcnt-cnpkupcnt">
                      <h4>Name: <span>Ryan</span></h4>
                  </div>
                  <div className="fst-btn"><a href="home"><button type="button" class="btn btn-outline-primary">CONFIRM</button></a></div>
                  <div className="two-btn"><a href="dropoff"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>

        </>
    )
}
export default Confirmationdropoff;