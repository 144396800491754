import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form, Tab, Nav, NavItem, Card } from 'react-bootstrap';
import './Iteamcovidvitamins.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Sidebar from "../sidebar";


const Iteamcovidvitamins = () => {


   useEffect(() => {
      document.title = `Iteamcovidvitamins | GRUBBRR`;
      document.getElementById('bodywrap').className='itamvtmnpgmain';

   },[])  
 


 
    return (
        <>

        
      <Navbar sidelink='iteamcovid' headerText={'VITAMINS & SUPPLEMENTS'} headerimg={'assets/images/logo.png'}/>
        <section className="iteampgsec">
            <Container fluid>
             <Row>
                 <Col xs={6} md={6} lg={6} className="text-center">
                   <div className="iteam-tpcnt">
                   <h3>Nature's Bounty Fish Oil </h3>
                   <h3>Softgels 1200mg</h3>
                   <p>$23.99</p>
                   <div className="qntybtntp"><span>QUANTITY</span></div>
                   <div class="stepper-input">
                    <button class="stepper-input__button">-</button>

                    <div class="stepper-input__content">
                        <input type="text" class="stepper-input__input" placeholder="1" />
                    </div>

                    <button class="stepper-input__button">+</button>
                </div>
                   </div>
                 </Col>
                 <Col xs={6} md={6} lg={6} className="text-center">
                   <img src="assets/images/crdtcnt-imgone.png" className="img-fluid w-25" alt="Plus"/>
                 </Col>
                 <Row className="sppgbtm-fixxdv">
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-lftcntbox"></Col>
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-rgtcntbox">
                    <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="txt-cnt">
                       <h4>Total: </h4>
                       <p>$7.25</p>
                    </div>
                    <div className="fst-btn"><a href="/"><button type="button" class="btn btn-outline-primary">Add to Cart</button></a></div>
                    <div className="two-btn"><a href="/"><button type="button" class="btn btn-outline-primary">Cancel</button></a></div>
                    </Col>
                 </Col>
                 </Row>
            </Row>
           </Container>
        </section>
  

        </>
    )
}
export default Iteamcovidvitamins;