import React, { useEffect } from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import './Shopvitamins.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const Shopvitamins = () => {


   useEffect(() => {
      document.title = `Shopvitamins | GRUBBRR`;
      document.getElementById('bodywrap').className='favmain';

   },[])  
 


 
    return (
        <>
         <Container fluid>
         <Row>
           <Col>
           <Button></Button>
           </Col>
         </Row>
       </Container>

        


  

        </>
    )
}
export default Shopvitamins;