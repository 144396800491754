import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function CollapsibleExample(props) {

  (function () {
  
    // Ideal time in seconds
    const idleDurationSecs = 300;

    // Redirect idle users to this URL
    const redirectUrl = 
        '/';
      
    // Variable to hold the timeout
    let idleTimeout; 
 

    const resetIdleTimeout = function () {

        // Clears the existing timeout
        if (idleTimeout) 
            clearTimeout(idleTimeout);
            // console.log(idleTimeout);
        // Set a new idle timeout to load the
        // redirectUrl after idleDurationSecs
        idleTimeout = setTimeout(() => window.location.href = redirectUrl, idleDurationSecs * 1000);
    };

    // Init on page load
    resetIdleTimeout();

    // Reset the idle timeout on any of
    // the events listed below
    ['click', 'touchstart', 'mousemove','onload','keypress','mousedown']
        .forEach(evt => document.addEventListener(evt, resetIdleTimeout, false)
    );
})();

  return (
    <Navbar className="p-0" collapseOnSelect expand="lg" variant="dark">
      <Container fluid className="px-0">
        <Navbar.Brand href="home"><img src={props.headerimg} className="img-fluid" alt="LOGO"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto lfttxtnev">
            <Nav.Link className="showtxt" href="#features">{props.headerText}</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="#deets"><img src="assets/images/cromimgnev.png" className="img-fluid" alt="Plus"/></Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              {/* <img src="assets/images/grubbrr-lognev.png" className="img-fluid" alt="Plus"/> */}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;