import React, { useEffect,useState } from 'react';
import {useHistory,useNavigate} from 'react-router-dom';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './CheckInList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../Constants/Api';
import $ from "jquery";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
const CheckInList = (props) => {
  const [getList, setList] = useState([]);
 
  const navigate = useNavigate();
  // console.log(props.location);

  const getApiData = () => {
   
    API.get('get_checkin')
    .then(response => {
   
      if (response.data.status == '0') 
      {
        
      } 
      else {
       
        setList(response.data.data);
        
       
      
      }
    }).catch(error => {
    });
      
  }
  
   useEffect(() => {
      document.title = `Pickup List | GRUBBRR`;
      document.getElementById('bodywrap').className='pcuppgmain';
      getApiData();
      setInterval(function(){
        
        getApiData();
       }, 30000);
       getApiDataImage();
   },[])  
 
   const [getImage, setImage] = useState('');
   const getApiDataImage = () => {
  
       API.get('get_settings')
       .then(response => {
       
         if (response.data.status == '0') 
         {
             setImage('assets/images/logo-old.png');
            
         } 
         else {
           
           setImage(response.data.data.image);
           
         }
       }).catch(error => {
       });
         
     }


 
    return (
        <>
       <Navbar sidelink='checkinlist' headerText={'Check In List'} headerimg={getImage}/>
       
        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col>
                  
                    <Button onClick={()=> navigate('/checkin')} className='btn btn-primary' style={{'float':'right','marginBottom':'15px'}}>CheckIn</Button>
                 
                    <Table  id="example" className="table table-striped table-bordered" style={{"width":"100%"}}>
                      <tr className='text-center'>
                          
                          <th style={{"font-size": "24px"}}>Name</th>
                          
                          <th style={{"font-size": "24px"}}>Mobile</th>
                          <th style={{"font-size": "24px"}}>Time</th>
                          <th style={{"font-size": "24px"}}>Status</th>
                        </tr>
                        <tbody>
                          {
                           
                            getList.length > 0 && getList.map((item, index) => {

                              
                                
                              

                                return (
                                  <tr>
                                   
                                    <td>{item.name}</td>
                                   
                                    <td>{item.mobile}</td>
                                    <td>{item.date}</td>
                                    <td>{item.status == 1  ? <Badge bg="success">Ready</Badge>  : <Badge bg="danger">Waiting</Badge> }</td>
                                  </tr>
                                )
                            }
                            )
                          }
                         
                         
                        </tbody>
                    </Table>
                </Col>

               
                
              </Row>
           </Container>
        </section>
        


  

        </>
    )
}
export default CheckInList;