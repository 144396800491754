import React, { useEffect, useState } from 'react';
import {useHistory,useNavigate} from 'react-router-dom';
import { Row, Col, Container, Button} from 'react-bootstrap';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from '../../Constants/Api';
import $ from "jquery";

const Home = () => {
    const [getImage, setImage] = useState('');
    const getApiData = () => {
   
        API.get('get_settings')
        .then(response => {
        
          if (response.data.status == '0') 
          {
              setImage('assets/images/logo-old.png');
             
          } 
          else {
            
            setImage(response.data.data.image);
            
          }
        }).catch(error => {
        });
          
      }
    

   useEffect(() => {
      document.title = `Home | GRUBBRR`;
      document.getElementById('bodywrap').className='homemain';
      getApiData();
   },[])  
    return (
        <>

        <section className="hmsecn">
            <Container fluid>
              <Row>
                <Col xs={12} md={12} lg={4} className="text-center hmdv-leftcnt">
                    <div><img src={getImage} className="mb-5 img-fluid w-md-50" alt="Home" style={{height:'100px'}}/></div>
                    <div className="my-3"><img src="assets/images/crome-img.png" className="lik img-fluid w-50" alt="Home"/></div>
                    <div><a href="scrensaver"><Button variant="outline-primary">Cancel</Button></a></div>
                </Col>
                <Col xs={12} md={12} lg={8}>
                    <Row className="rightside-boxcnt">
                     <Col xs={12} md={6} lg={6} className="text-center">
                     <a href="pickup">
                         <div className="p-3">
                             <img src="assets/images/home-imgone.png" className="lik img-fluid " alt="Home"/>
                             <p>Pick Up</p>
                         </div>
                         </a>
                     </Col>
                     <Col xs={12} md={6} lg={6} className="text-center">
                     <a href="checkin">
                         <div className="p-3">
                             <img src="assets/images/home-imgfour.png" className="lik img-fluid " alt="Home"/>
                             <p>Appointment Check-In</p>
                         </div>
                         </a>
                     </Col>
                     <Col xs={12} md={6} lg={6} className="text-center">
                     <a href="shop">
                         <div className="p-3">
                             <img src="assets/images/home-imgthree.png" className="lik img-fluid " alt="Home"/>
                             <p>Shop Pharmacy</p>
                         </div>
                         </a>
                     </Col>
                     <Col xs={12} md={6} lg={6} className="text-center">
                     <a href="scheduleappt">
                         <div className="p-3">
                             <img src="assets/images/home-imgtwo.png" className="lik img-fluid " alt="Home"/>
                             <p>Schedule Appointment</p>
                         </div>
                         </a>
                     </Col>
                    </Row>
                </Col>
              </Row>
           </Container>
        </section>


  

        </>
    )
}
export default Home;