import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Home from './Pages/Home/Home';
import Afternoon from './Pages/Afternoon/Afternoon';
import Checkin from './Pages/Checkin/Checkin';
import Checkout from './Pages/Checkout/Checkout';
import Confirmation from './Pages/Confirmation/Confirmation';
import Confirmationdropoff from './Pages/Confirmationdropoff/Confirmationdropoff';
import Confirmationpickup from './Pages/Confirmationpickup/Confirmationpickup';
import Confirmationpickupn from './Pages/Confirmationpickupn/Confirmationpickupn';
import Dropoff from './Pages/Dropoff/Dropoff';
import Iteamcovid from './Pages/Iteamcovid/Iteamcovid';
import Iteamcovidvitamins from './Pages/Iteamcovidvitamins/Iteamcovidvitamins';
import Morning from './Pages/Morning/Morning';
import Pickup from './Pages/Pickup/Pickup';
import Scheduleappt from './Pages/Scheduleappt/Scheduleappt';
import Scrensaver from './Pages/Scrensaver/Scrensaver';
import Selectdate from './Pages/Selectdate/Selectdate';
import Selecttime from './Pages/Selecttime/Selecttime';
import Shop from './Pages/Shop/Shop';
import List from './Pages/Pickuplist/List';
import CheckInList from './Pages/CheckInList/CheckInList';


import Shopvitamins from './Pages/Shopvitamins/Shopvitamins';
import Constant from './Constants/Constants';
function App() {
 
  return (
    <>
      <Router>
              <Routes>
                  <Route exact path="/" element={<Scrensaver />} /> 
                  <Route exact path="/home/" element={<Home />} />
                  <Route exact path="/afternoon/" element={<Afternoon />} />
                  <Route exact path="/checkin/" element={<Checkin />} />
                  <Route exact path="/checkout/" element={<Checkout />} />
                  <Route exact path="/confirmation/" element={<Confirmation />} />
                  <Route exact path="/confirmationdropoff/" element={<Confirmationdropoff />} />
                  <Route exact path="/confirmationpickup/" element={<Confirmationpickup />} />
                  <Route exact path="/confirmationpickupn/" element={<Confirmationpickupn />} />
                  <Route exact path="/dropoff/" element={<Dropoff />} />
                  <Route exact path="/iteamcovid/" element={<Iteamcovid />} />
                  <Route exact path="/iteamcovidvitamins/" element={<Iteamcovidvitamins />} />
                  <Route exact path="/morning" element={<Morning />} /> 
                  <Route exact path="/pickup/" element={<Pickup />} />
                  <Route exact path="/list/" element={<List />} />
                  <Route exact path="/checkinlist/" element={<CheckInList />} />
                  
                  <Route exact path="/scheduleappt/" element={<Scheduleappt />} />
                  <Route exact path="/scrensaver/" element={<Scrensaver />} />
                  <Route exact path="/selectdate/" element={<Selectdate />} />
                  <Route exact path="/selecttime/" element={<Selecttime />} />
                  <Route exact path="/shop/" element={<Shop />} />
                  <Route exact path="/shopvitamins/" element={<Shopvitamins />} />
              </Routes>
      </Router>
                
    </>
  );
}

export default App;