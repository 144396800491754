import React, { useEffect } from 'react';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Dropoff.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";


const Dropoff = () => {


   useEffect(() => {
      document.title = `Dropoff | GRUBBRR`;
      document.getElementById('bodywrap').className='dropofmain';

   },[])  
 


 
    return (
        <>
      
        <Navbar sidelink='dropoff' headerText={'DROP OFF'} headerimg={'assets/images/logo-blacknav.png'}/>

        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-start ckeinpg-lftcnt">
                  <div>
                     <h3>Please type in your name & phone number below to drop off your prescription.</h3>
                  </div>

                  <Form className="">
                     <Form.Group className="mb-3 fltng-inpt" controlId="formBasicPassword">
                       <Form.Control type="name" placeholder="Name" />
                       <Form.Label>Name</Form.Label>
                     </Form.Group>
                     <Form.Group className="mb-3 fltng-inpt" controlId="formBasicPassword">
                       <Form.Control type="number" name="telphone" placeholder="Phone Number" />
                       <Form.Label>Phone Number</Form.Label>
                     </Form.Group>
                   </Form>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt">
                  <div className="fst-btn"><a href="confirmationdropoff"><button type="button" class="btn btn-outline-primary">NEXT</button></a></div>
                  <div className="two-btn"><a href="home"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>

        </>
    )
}
export default Dropoff;