import React, { useEffect,useState } from 'react';
import { useParams,useSearchParams,useNavigate  } from "react-router-dom";
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Confirmationpickup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import moment from 'moment';
import API from '../../Constants/Api';
import $ from "jquery";

const Confirmationpickup = () => {

  const [getError, setError] = useState('');
  const [getSuccess, setSuccess] = useState('');

   useEffect(() => {
      document.title = `Confirmationpickup | GRUBBRR`;
      document.getElementById('bodywrap').className='cnfpkupmain';
      getApiDataImage();
   },[])  
 
   
   let [searchParams] = useSearchParams();
   let name =searchParams.get('q');
   let mobile =searchParams.get('type');
   let time = searchParams.get('time');
   var today="";
   var today_time="";
   if(time != "")
   {
     today = moment(new Date()).format('MMMM D, YYYY');
     today_time = moment(today+ ' ' + time).format('hh:mm A')
   }

   const navigate = useNavigate();

   const handleChange = () => {
  
    API.post('add_appointment', {
      name: name,
      mobile: mobile,
      time: time,
    })
      .then(response => {
        if (response.data.status == '0') 
        {
          
          setError(response.data.message);
          $('#bankDetailsMsg').html('<span class="text-danger">' + response.data.message + '</span>');
        } 
        else {
          $('#bankDetailsMsg').html('<span class="text-success">' + response.data.message + '</span>');
          setSuccess(response.data.message);
          setTimeout(() => {
            navigate('/checkinlist');
          }, 4000);
        
        }
         
      }).catch(error => {
      });
   }
 
   const [getImage, setImage] = useState('');
   const getApiDataImage = () => {
  
       API.get('get_settings')
       .then(response => {
       
         if (response.data.status == '0') 
         {
             setImage('assets/images/logo-old.png');
            
         } 
         else {
           
           setImage(response.data.data.image);
           
         }
       }).catch(error => {
       });
         
     }

    return (
        <>
        <Navbar sidelink='confirmationpickup' headerText={'CONFIRMATION'} headerimg={getImage}/>
        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-lftcnt">
                  <div>
                  </div>
                  <div className="text-center mb-4"><img src="assets/images/svg/check-img.svg" className="img-fluid" alt="Plus"/></div>
                  <h3>Thank You for Checking In</h3>
                  <p>Please have a seat, a nurse will be with you shortly</p>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt cnfrmpcuppg">
                <div class="mb-3" id="bankDetailsMsg"></div>
                  <div className="mb-4 tpcnt-cnpkupcnt">
                      <h4>Name: <span>{name}</span></h4>
                      <p className="fw-bold">{today_time}</p>
                      <p>{moment().format('dddd').toUpperCase()}, {moment().format('MMMM').toUpperCase()} {moment().format('D, YYYY')}</p>
                  </div>
                  <div className="fst-btn"><a onClick={()=> handleChange()}><button type="button" class="btn btn-outline-primary">CONFIRM</button></a></div>
                  <div className="two-btn"><a href="home"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>


  

        </>
    )
}
export default Confirmationpickup;