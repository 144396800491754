import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Confirmation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import { useParams,useSearchParams,useNavigate  } from "react-router-dom";
import moment from 'moment';
import API from '../../Constants/Api';
import $ from "jquery";

const Confirmation = () => {

  const [getError, setError] = useState('');
  const [getSuccess, setSuccess] = useState('');

  let [searchParams] = useSearchParams();
  let name =searchParams.get('n');
  let mobile =searchParams.get('m');
  let email =searchParams.get('e');
  let status =searchParams.get('s');
  let finalDate = searchParams.get('f');

  var today_time="";
  var d = "";
  var m = "";
  var y = "";
   if(finalDate != "")
   {
    var current_date = moment(finalDate, 'DD-MM-YYYY hh:mm a');
    today_time = moment(current_date).format('h:mm A');
    d = moment(current_date).format('dddd').toUpperCase();
    m = moment(current_date).format('MMMM').toUpperCase();
    y = moment(current_date).format('D ,YYYY').toUpperCase();
    console.log(y);
   }
   const navigate = useNavigate();

  console.log(today_time);

 useEffect(() => {
      document.title = `Confirmation | GRUBBRR`;
      document.getElementById('bodywrap').className='cnfrmtnfnpgmain';
      getApiDataImage();
   },[])  
 

   const dataSave = () => 
   {
       if(name == "")
       {
         alert('Name Required');
       }
       else if(mobile == "")
       {
         alert('Mobile Required');
       }
       else if(email == "")
       {
         alert('Email Required');
       }
       else if(finalDate == "")
       {
         alert('Please select time slot');
       }
       else
       {
        API.post('store_appointment', {
          name: name,
          mobile: mobile,
          email: email,
          time: finalDate,
          status:status
        })
          .then(response => {
            if (response.data.status == '0') 
            {
              
              setError(response.data.message);
              $('#bankDetailsMsg').html('<span class="text-danger">' + response.data.message + '</span>');
            } 
            else {
              $('#bankDetailsMsg').html('<span class="text-success">' + response.data.message + '</span>');
              setSuccess(response.data.message);
              setTimeout(() => {
                navigate('/home');
              }, 4000);
            
            }
             
          }).catch(error => {
          });
       }
   }

   const [getImage, setImage] = useState('');
   const getApiDataImage = () => {
  
       API.get('get_settings')
       .then(response => {
       
         if (response.data.status == '0') 
         {
             setImage('assets/images/logo-old.png');
            
         } 
         else {
           
           setImage(response.data.data.image);
           
         }
       }).catch(error => {
       });
         
     }


 
    return (
        <>
        <Navbar sidelink='confirmation' headerText={'CONFIRMATION'} headerimg={getImage}/>
        <section className="cnfrmtnpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-center cnfmtnpg-lftcnt">
                  <div>
                  </div>
                  <h3>SEE YOU SOON!</h3>
                  <p>Don't Forget to Check Your Appointment Details</p>
                  <div className="img-boxx text-center my-md-5 my-3"><img src="assets/images/final-render.gif" className="img-fluid" alt="Plus"/></div>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center cnfmtnpg-rgtcnt">
                <div class="mb-3" id="bankDetailsMsg"></div>
                  <div className="mb-4 tpcnt-cnpkupcnt">
                      <h4>Name: <span>{name}</span></h4>
                      {/* <h4>Mobile: <span>{mobile}</span></h4>
                      <h4>Email: <span>{email}</span></h4> */}
                      <p className="fw-bold">{today_time}</p>
                      <p>{d}, {m} {y}</p>
                  </div>
                  <div className="fst-btn"><a onClick={()=> dataSave()}><button type="button" class="btn btn-outline-primary">CONFIRM</button></a></div>
                  <div className="two-btn"><a href="scheduleappt"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>


  

        </>
    )
}
export default Confirmation;