import React, { useEffect,useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { Row, Col, Container, Button} from 'react-bootstrap';
import './List.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../Constants/Api';

import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
const List = (props) => {
  const [getList, setList] = useState([]);
 
  const navigate = useNavigate();
  // console.log(props.location);

  const getApiData = () => {
   
    API.get('get_pickup')
    .then(response => {
   
      if (response.data.status === '0') 
      {
        
      } 
      else {
       
        setList(response.data.data);
        
       
      
      }
    }).catch(error => {
    });
      
  }
  
   useEffect(() => {
      document.title = `Pickup List | GRUBBRR`;
      document.getElementById('bodywrap').className='pcuppgmain';
      getApiData();
      getApiDataImage();
      setInterval(function(){
        console.log(1);
        getApiData();
       }, 30000);
   },[])  
 
   const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status === '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }
   


 
    return (
        <>
       <Navbar sidelink='list' headerText={'PICK UP'} headerimg={getImage}/>
       
        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col>
                  
                    <Button onClick={()=> navigate('/pickup')} className='btn btn-primary' style={{'float':'right','marginBottom':'15px'}}>Pickup</Button>
                 
                    <Table  id="example" className="table table-striped table-bordered" style={{"width":"100%"}}>
                      <tr className='text-center'>
                          
                          <th style={{"font-size": "24px"}}>Name</th>
                          
                          <th style={{"font-size": "24px"}}>Order Number</th>
                          <th style={{"font-size": "24px"}}>Time</th>
                          <th style={{"font-size": "24px"}}>Status</th>
                        </tr>
                        <tbody>
                          {
                           
                            getList.length > 0 && getList.map((item, index) => {

                              
                                
                              

                                return (
                                  <tr>
                                   
                                    <td>{item.name}</td>
                                   
                                    <td>#{item.order_number}</td>
                                    <td>{item.date}</td>
                                    <td>{item.status === 1  ? <Badge bg="success">Ready</Badge>  : <Badge bg="danger">Waiting</Badge> }</td>
                                  </tr>
                                )
                            }
                            )
                          }
                         
                         
                        </tbody>
                    </Table>
                </Col>

               
                
              </Row>
           </Container>
        </section>
        


  

        </>
    )
}
export default List;