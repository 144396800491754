import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import './Checkin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import $ from "jquery";
import API from '../../Constants/Api';
const Checkin = () => {

  const [getDate, setDate] = useState([]);
  const [getName, setName] = useState('');
  const [getMobile, setMobile] = useState('');
  const [getTime, setTime] = useState(moment().minutes(Math.ceil(moment().minutes() / 15) * 15).format('hh:mm A'));

  
  function intervals(startString, endString) {
    var start = moment(startString, 'hh:mm');
    var end = moment(endString, 'hh:mm');

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
   
    var result = [];

    var current = moment(start);
   
    while (current <= end) {
      
      result.push(current.format('hh:mm A'));
      current.add(15, 'minutes');
    }

    //setDate(result);

    return result;
  }




  


  //console.log(Math.floor(Math.random()*99999999900+100000000000));

  // var startTime = moment().startOf('day');
  // var endTime = moment().endOf('day');
  var startTime = moment().utc().set({hour:'09',minute:'00'});
  var endTime = moment().utc().set({hour:'17',minute:'59'});

  const getApiData = () => {
   console.log(getTime)
    setDate(intervals(startTime, endTime));
    API.get('get_settings')
    .then(response => {
   console.log(response.data.status);
      if (response.data.status == '0') 
      {
          setName('');
          setMobile('');
      } 
      else {
       
        setName(response.data.data.check_in_name);
        setMobile(response.data.data.check_in_mobile);
        //setTime(response.data.data.check_in_time);
      
      }
    }).catch(error => {
    });
      
  }


  useEffect(() => {
    document.title = `Checkin | GRUBBRR`;
    document.getElementById('bodywrap').className = 'favmain';
    getApiData();
    getApiDataImage();
  }, []);
  const navigate = useNavigate();

  const handlePickup = () => {

    if (getName == "" && getMobile == "" && getTime == "") {
      alert('Detail is required');
    }
    else if (getName == "") {
      alert('Name is required');
    }
    else if (getMobile == "") {
      alert('Phone Number is required');
    }
    else if (getTime == "") {
      alert('Time is required');
    }
    else {

      navigate("/confirmationpickup?q=" + getName + "&type=" + getMobile + "&time=" + getTime);
      //window.location.href = "confirmationpickup";
    }


  };

  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }


  return (
    <>
      <Navbar sidelink='checkin' headerText={'CHECK IN'} headerimg={getImage} />

      <section className="ckeinpgsec">
        <Container fluid>
          <Row>
            <Col lg={6} md={12} xs={12} className="text-start ckeinpg-lftcnt">
              <div>
                <h3>Please type in your contact information below to check in for your appointment.</h3>
              </div>

              <Form className="">
                <Form.Group className="mb-3 fltng-inpt" controlId="name">
                  <Form.Control type="name" value={getName} onChange={e => setName(e.target.value)} placeholder="Name" id="name" required />
                  <Form.Label>Name</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3 fltng-inpt" controlId="number" >
                  <Form.Control type="text" name="number" value={getMobile} onChange={e => setMobile(e.target.value)} id="number" placeholder="Phone Number" required />
                  <Form.Label>Phone Number</Form.Label>
                </Form.Group>

                <Form.Group className="" controlId="date_time">

                  <label style={{
                    marginBottom: 0,
                    'font-size': 25,
                    'font-weight': 'bold',
                    

                  }}>Appointment Time</label>
                  <select className='form-control' value={getTime} onChange={e =>  setTime(e.target.value)} ref={el => {
                    if (el) {

                      el.style.setProperty('padding', '0', 'important');
                      
                    }
                  }}>
                    {
                      getDate.length > 0 && getDate.map((item, index) => {
                        var select = getTime === item ? "selected": ""
                       
                        return (
                          <option selected={select}>{item}</option>
                        )

                      })
                    }
                  </select>



                </Form.Group>
              </Form>

            </Col>
            <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt">
              <div className="fst-btn"><a onClick={() => handlePickup()}><button type="button" class="btn btn-outline-primary">NEXT</button></a></div>
              <div className="two-btn"><a href="home"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
            </Col>
          </Row>
        </Container>
      </section>




    </>
  )
}
export default Checkin;