
import React, { useEffect,useState } from 'react';
import { useParams,useSearchParams,useNavigate  } from "react-router-dom";
import { Row, Col, Container, Button, Form, Tab, Nav, NavItem, Card } from 'react-bootstrap';
import './Iteamcovid.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Sidebar from "../sidebar";
import API from '../../Constants/Api';
import $ from "jquery";
const Iteamcovid = () => {
  const navigate = useNavigate();

   const [getProduct, setProduct] = useState(null);
   
   const [val, setVal] = useState(1);
   const [max_qty, setMax_qty] = useState(0);

   let [searchParams] = useSearchParams();
   let id =searchParams.get('id');

   let oldItems=[];
  
   
 
   useEffect(() => {
    oldItems = JSON.parse(localStorage.getItem('cart')) || [];
    
        var match = oldItems.find(function (item) {
            return item['id'] === parseInt(id);
        });
        if (match) {
         
          setVal(match['qty']);
          
        } 
    
      API.get('get_product_detail/'+id)
      .then(response => {
        if (response.data.status == '0') 
        {
        } 
        else {
          setProduct(response.data.data);
          setMax_qty(response.data.data.qty);
        }
      }).catch(error => {
      });

      document.title = `Iteamcovid | GRUBBRR`;
      document.getElementById('bodywrap').className='itamcvdpgmain';
      getApiDataImage();
   },[])  


   const add_to_cart=(id)=>{
    oldItems = JSON.parse(localStorage.getItem('cart')) || [];
    var match = oldItems.find(function (item) {
        return item['id'] === id;
    });
    if (match) {
        match['name'] = getProduct.name;
        match['qty'] = parseInt(val);
        match['price'] = parseInt(val) * parseFloat(getProduct.price);
        match['image'] = getProduct.product_image;
    } else {
        var newItem = {
            'id': id,
            'name':getProduct.name,
            'qty': parseInt(val),
            'price': parseInt(val) * parseFloat(getProduct.price),
            'image':getProduct.product_image,
            'original_price':parseFloat(getProduct.price),
            'max_qty':max_qty,
        };
        oldItems.push(newItem);
    }
    localStorage.setItem('cart', JSON.stringify(oldItems));
    
    navigate('/checkout');
   }

   const handleChange = (e) => {
     const regex = /^[0-9\b]+$/;
     if (e.target.value === "" || regex.test(e.target.value)) 
     {
        if(e.target.value <= max_qty)
        {
          setVal(e.target.value);
        }
       
     }
     else{
      
     }
   };

   const qty_plus = (id) => {
    // localStorage.clear();
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) 
    {
       if(val < max_qty)
       {
          var qtyp = val + 1;
         setVal(qtyp);

          oldItems = JSON.parse(localStorage.getItem('cart')) || [];
         var match = oldItems.find(function (item) {
             return item['id'] === id;
         });
         if (match) {
             match['name'] = getProduct.name;
             match['qty'] = parseInt(qtyp);
             match['price'] = parseInt(qtyp) * parseFloat(getProduct.price);
             match['image'] = getProduct.product_image;
         } else {
             var newItem = {
                 'id': id,
                 'name':getProduct.name,
                 'qty': parseInt(qtyp),
                 'price': parseInt(qtyp) * parseFloat(getProduct.price),
                 'image':getProduct.product_image,
                 'original_price':parseFloat(getProduct.price),
                 'max_qty':max_qty,
             };
             oldItems.push(newItem);
         }
         localStorage.setItem('cart', JSON.stringify(oldItems));
   
         console.log(localStorage.getItem('cart'));
         
       }
    }
    else{
     
    }
  };

  const qty_minus = (id) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) 
    {
       if(val > 1)
       {
        var qtyp = val-1;
         setVal(qtyp);

           oldItems = JSON.parse(localStorage.getItem('cart')) || [];
          var match = oldItems.find(function (item) {
              return item['id'] === id;
          });
          if (match) {
            match['name'] = getProduct.name;
              match['qty'] = parseInt(qtyp);
              match['price'] = parseInt(qtyp) * parseFloat(getProduct.price);
              match['image'] = getProduct.product_image;
          }
          localStorage.setItem('cart', JSON.stringify(oldItems));
    
          
       }
       else if(val == 1)
       {
        var qtyp = val-1;
         oldItems = JSON.parse(localStorage.getItem('cart')) || [];
        oldItems = oldItems.filter((item) => item.id !== id);
         
          localStorage.setItem('cart', JSON.stringify(oldItems));

    
          
       }
       console.log(localStorage.getItem('cart'));
       
      
    }
    else{
     
    }
  };

  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }
   


    return (
        <>

        
      <Navbar sidelink='iteamcovid' headerText={getProduct === null ? "" :  getProduct.category_name} headerimg={getImage}/>

      { getProduct != null ? 
        <section className="iteampgsec">
            <Container fluid>
             <Row>
                 <Col xs={12} md={6} lg={6}   className="text-center">
                   <div className="iteam-tpcnt">
                   <h3>{getProduct.name} </h3>
                   
                   <p>${getProduct.price}</p>
                   <div className="qntybtntp"><span>QUANTITY</span></div>
                   <div class="stepper-input">
                    <button class="stepper-input__button" onClick={()=>qty_minus(getProduct.id)} id='qtyminus'>-</button>

                    <div class="stepper-input__content">
                        <input type="text" value={val} onChange={handleChange}  class="stepper-input__input" id="qty_text"  placeholder="1" />
                    </div>

                    <button class="stepper-input__button" onClick={()=>qty_plus(getProduct.id)} id='qty_plus'>+</button>
                </div>
                   </div>
                 </Col>
                 <Col xs={12} md={6} lg={6}  className="text-center pt-3">
                   <img src={getProduct.product_image} className="img-fluid w-md-50" alt={getProduct.name} title={getProduct.name}/>
                 </Col>
               
            </Row>
           </Container>
        </section>

       

        :""
      }
       <section className="itemabc">
          <Container fluid className='px-0'>
          <Row className="sppgbtm-fixxdv">
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-lftcntbox"></Col>
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-rgtcntbox">
                    <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="txt-cnt">
                       <h4>Total: </h4>
                       <p>$ {getProduct === null ? "0" :  (getProduct.price * val).toFixed(2)}</p>
                    </div>
                    <div className="fst-btn"><a onClick={()=>add_to_cart(id) }><button type="button" class="btn btn-outline-primary">Add to Cart</button></a></div>
                    <div className="two-btn"><a href="/"><button type="button" class="btn btn-outline-primary">Cancel</button></a></div>
                    </Col>
                 </Col>
                 </Row>
          </Container>
          </section>

        </>
    )
}
export default Iteamcovid;