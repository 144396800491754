import React, { useEffect,useState } from 'react';
import {useHistory,useNavigate} from 'react-router-dom';
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Pickup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../Constants/Api';
import $ from "jquery";

const Pickup = (props) => {

 
  const [getName, setName] = useState('');
  const [getMobile, setMobile] = useState('');

  const navigate = useNavigate();
  // console.log(props.location);

  const getApiData = () => {
   
    API.get('get_settings')
    .then(response => {

      if (response.data.status == '0') 
      {
          setName('');
          setMobile('');
      } 
      else {
       
        setName(response.data.data.pickup_name);
        setMobile(response.data.data.pickup_mobile);
       
      
      }
    }).catch(error => {
    });
      
  }

   useEffect(() => {
      document.title = `Pickup | GRUBBRR`;
      document.getElementById('bodywrap').className='pcuppgmain';
      getApiData();
      getApiDataImage();
   },[])  
 
   const handlePickup = () => 
   {
    
    if(getName == "" && getMobile == "")
    {
      alert('Detail is required');
    }
    else if(getName == "")
    {
      alert('Name is required');
    }
    else if (getMobile == "")
    {
      alert('Phone Number is required');
    }
    else
    {
      
      navigate("/confirmationpickupn?q=" + getName + "&type="+getMobile);
        //window.location.href = "confirmationpickup";
    }
      
    
  };


  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }



 
    return (
        <>
       <Navbar sidelink='pickup' headerText={'PICK UP'} headerimg={getImage}/>
       
        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-start ckeinpg-lftcnt">
                  <div>
                     <h3>Please type in your name & phone number below to pick up your prescription.</h3>
                  </div>

                  <Form className="">
                     <Form.Group className="mb-3 fltng-inpt" controlId="name">
                       <Form.Control type="name" placeholder="Name" id="name" value={getName} onChange={e => setName(e.target.value)}  required/>
                       <Form.Label>Name</Form.Label>
                     </Form.Group>
                     <Form.Group className="mb-3 fltng-inpt" controlId="number">
                       <Form.Control type="text" id="number" onChange={e => setMobile(e.target.value)} name="mobile" placeholder="Phone Number" value={getMobile} required/>
                       <Form.Label>Phone Number</Form.Label>
                     </Form.Group>
                   </Form>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt">
                  <div className="fst-btn"><a onClick={() => handlePickup()}><button type="button" class="btn btn-outline-primary">NEXT</button></a></div>
                  <div className="two-btn"><a href="home"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>
        


  

        </>
    )
}
export default Pickup;