import React, { useEffect, useState  } from 'react';
import { useParams,useSearchParams,useNavigate  } from "react-router-dom";
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Selectdate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import DatePicker from "react-horizontal-datepicker";
import moment from "moment";
import $ from "jquery";
import API from '../../Constants/Api';
const Selectdate = () => {

  const [getMonth, setMonth] = useState('');
  const [getTimes, setTimes] = useState([]);
  const [getTimesAfternoon, setTimesAfternoon] = useState([]);
  const [getStatus, setStatus] = useState('');

  const [getSelectDate, setSelectDate] = useState(null);
  const [getDate, setDate] = useState(null);
  const [getFinalDate, setFinalDate] = useState(null);

  const [getMorningAppointment, setMorningAppointment] = useState(0);
  const [getAfternoonAppointment, setAfternoonAppointment] = useState(0);

  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let name =searchParams.get('n');
  let mobile =searchParams.get('m');
  let email =searchParams.get('e');


  const dataSave = () => 
    {
        if(name == "")
        {
          alert('Name Required');
        }
        else if(mobile == "")
        {
          alert('Mobile Required');
        }
        else if(email == "")
        {
          alert('Email Required');
        }
        else if(getStatus == "" || getFinalDate == null)
        {
          alert('Please select time slot');
        }
        else
        {
          
          navigate("/confirmation?n=" + name + "&m="+mobile+"&e="+email+"&f="+getFinalDate+"&s="+getStatus);
        }
    }

 

  function date_time_morning()
  {
      API.get('get/morning/'+getSelectDate)
      .then(response => {
     
        if (response.data.status == '0') 
        {
          setTimes([]);
        } 
        else {
 
        setTimes(response.data.data);
          
          var i = 0 ; 
          getTimes.map((item, index) => {
            if(item.status == 0)
            {
              i++;
            }
        });
  
        setMorningAppointment(i);
      
        }
      }).catch(error => {
      });
        
  }

  function date_time_afternoon()
  {
   
    API.get('get/afternoon/'+getSelectDate)
    .then(response => {
    
      if (response.data.status == '0') 
      {
        setTimesAfternoon([]);
      } 
      else {
        
        setTimesAfternoon(response.data.data);
        var j =0;
        getTimesAfternoon.map((item, index) => {
          if(item.status == 0)
          {
            j++;
          }
      });
     
      setAfternoonAppointment(j);
          
      }
    }).catch(error => {
    });
  }

  
   useEffect(() => {
    
      document.title = `Selectdate | GRUBBRR`;
      document.getElementById('bodywrap').className='favmain';

      getApiDataImage();
      
   },[])  
 
   const selectedDay = (val) =>{

    if(val != null)
    {
      setStatus('');
      setTimes([]);
      setTimesAfternoon([]);
      setMorningAppointment(0);
      setAfternoonAppointment(0);
    
      var current_date = moment(val).format("DD-MM-YYYY");
     
      var select_date = moment(new Date()).format("HH:mm");

        setDate(current_date);
        setSelectDate(current_date + ' ' + select_date);
      
        setMonth(moment(val).format('MMMM'));
        $('#appointment_count').show();

        API.get('get/afternoon/'+current_date + ' ' + select_date)
        .then(response => {
    
      if (response.data.status == '0') 
      {
        setTimesAfternoon([]);
      } 
      else {
       
        
        setTimesAfternoon(response.data.data);
        var j =0;
        response.data.data.map((item, index) => {
          if(item.status == 0)
          {
            j++;
          }
      });
     
      setAfternoonAppointment(j);
          
      }
          }).catch(error => {
          });

          API.get('get/morning/'+current_date + ' ' + select_date)
            .then(response => {
          
              if (response.data.status == '0') 
              {
                setTimes([]);
              } 
              else {
      
              setTimes(response.data.data);
                
                var i = 0 ; 
                response.data.data.map((item, index) => {
                  if(item.status == 0)
                  {
                    i++;
                  }
              });
        
              setMorningAppointment(i);
            
              }
            }).catch(error => {
            });
    }
        
    };

   

    $(document).on("click",".morning_active",function()
    {
      $('.morning_active').removeClass('morning_set_active');
      
      // Add active class to the clicked li element
      $(this).addClass('morning_set_active');

      var date_time  = getDate +' '+ $(this).attr('data-id');

      var current_date = moment(date_time, 'YYYY-MM-DD hh:mm a');
     
      setFinalDate(current_date._i);
      
    });


    $(document).on("click",".afternoon_active",function()
    {
      $('.afternoon_active').removeClass('afternoon_set_active');
      
      // Add active class to the clicked li element
      $(this).addClass('afternoon_set_active');

      var date_time  = getDate +' '+ $(this).attr('data-id');

      var current_date = moment(date_time, 'YYYY-MM-DD hh:mm a');
     
      setFinalDate(current_date._i);
      
    });



    const onClick_morning = () => 
    {
     
      setStatus('morning');
      $('.main-morning').css('border','2px solid #335e90');
      $('.main-afternoon').css('border','none');
    }
    const onClick_afternoon = () => 
    {
      setStatus('afternoon');
      $('.main-afternoon').css('border','2px solid #335e90');
      $('.main-morning').css('border','none');
    }


    const [getImage, setImage] = useState('');
    const getApiDataImage = () => {
   
        API.get('get_settings')
        .then(response => {
        
          if (response.data.status == '0') 
          {
              setImage('assets/images/logo-old.png');
             
          } 
          else {
            
            setImage(response.data.data.image);
            
          }
        }).catch(error => {
        });
          
      }

 
    return (
        <>

        <Navbar sidelink='scheduleappt' headerText={'APPOINTMENT'} headerimg={getImage}/>

        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-start ckeinpg-lftcnt align-self-start">
                  <div>
                     <h3>{getMonth}</h3>
                     <DatePicker 
                     endDate={100}
                     getSelectedDay={selectedDay}
                      labelFormat={"MMMM"}
                      color={"#374e8c"}   
                     
                      />
                  </div>

                  <div className='row' id="appointment_count" style={{'display':'none'}}>
                    <div className='col-md-6 col-sm-12'>
                      <div className='main-morning'>
                        <div className='morning-image'>
                            <img src={'assets/images/sunrise.png'}/>            
                          </div>
                          <div className='morning' onClick={() => onClick_morning()}>
                            <p>Morning</p>  
                            <p className='appointment'>{getMorningAppointment} Appointments available</p>                              
                          </div>
                      </div>
                      
                    </div>
                    <div className='col-md-6 col-sm-12'>

                    <div className='main-afternoon' onClick={() => onClick_afternoon()}>
                        <div className='afternoon-image'>
                            <img src={'assets/images/sunny.png'}/>            
                          </div>
                          <div className='afternoon'>
                            <p>Afternoon</p>  
                            <p className='appointment'>{getAfternoonAppointment} Appointments available</p>                              
                          </div>
                      </div>

                      
                    </div>
                  </div>

                  
                  <div className='row'>
                    {
                      getStatus == "afternoon" && getTimesAfternoon.length > 0 && getTimesAfternoon.map((item, index) => {
                        {
                         
                          
                          let set_id = 'set_active'+ index; 
                        return(
                          
                            item.status != '0' 
                            ?
                            <div className='col-md-3 col-sm-12'>
                             
                              
                            <div className='main-time align-self-center'  id={set_id} style={{'background':'lightgray',color: 'lightslategray'}} >
                                <p className='align-self-center'>{item.times}</p>
                            </div>
                          </div> 
                            :
                            <div className='col-md-3 col-sm-12'>
                             
                              
                            <div className='main-time align-self-center afternoon_active' data-id={item.times} id={set_id} >
                                <p className='align-self-center'>{item.times}</p>
                            </div>
                          </div>
                         
                        )
                        }
                      })
                     
                    }
                    
                   

                  </div>

                  <div className='row'>
                  {
                      getStatus == "morning" && getTimes.length > 0 && getTimes.map((item, index) => {
                        {
                          
                          let set_morning_id = 'set_morning_active'+ index; 
                          return(
                            
                              item.status != '0' 
                              ?
                              <div className='col-md-3 col-sm-12'>
                               
                                
                              <div className='main-time align-self-center' style={{'background':'lightgray',color: 'lightslategray'}} id={set_morning_id}  >
                                  <p className='align-self-center'>{item.times}</p>
                              </div>
                            </div> 
                              :
                              <div className='col-md-3 col-sm-12'>
                               
                                
                              <div className='main-time align-self-center morning_active' id={set_morning_id} data-id={item.times}>
                                  <p className='align-self-center'>{item.times}</p>
                              </div>
                            </div>
                           
                          )
                          }

                      })
                    }
                    
                  </div>

                  

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt">
                  <div className="fst-btn"><a  onClick={()=> dataSave() }><button type="button" class="btn btn-outline-primary">NEXT</button></a></div>
                  <div className="two-btn"><a href="/"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>

        </>
    )
}
export default Selectdate;