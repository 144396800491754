import React, { useEffect,useState } from 'react';
import { useParams,useSearchParams,useNavigate  } from "react-router-dom";
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import './Confirmationpickupn.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../Constants/Api';
import $ from "jquery";
import moment from 'moment';

const Confirmationpickupn = () => {
  const [getCode, setCode] = useState('');

  const [getError, setError] = useState('');
  const [getSuccess, setSuccess] = useState('');

   useEffect(() => {
    API.get('get_code')
    .then(response => {
      if (response.data.status === 0) 
      {
        window.location.reload();
      } 
      else {
       
        setCode(response.data.data);
      }
    }).catch(error => {
    });
    getApiDataImage();
      document.title = `Confirmationpickupn | GRUBBRR`;
      document.getElementById('bodywrap').className='cnfpkupmain';

   },[])  

   let [searchParams] = useSearchParams();
   let name =searchParams.get('q');
   let mobile =searchParams.get('type');
   const navigate = useNavigate();

   const handleChange = () => {
  
    API.post('add_pickup', {
      name: name,
      mobile: mobile,
      code:getCode,
    })
      .then(response => {
        if (response.data.status == '0') 
        {
          
          setError(response.data.message);
          $('#bankDetailsMsg').html('<span class="text-danger">' + response.data.message + '</span>');
        } 
        else {
          $('#bankDetailsMsg').html('<span class="text-success">' + response.data.message + '</span>');
          setSuccess(response.data.message);
          setTimeout(() => {
            navigate('/list');
          }, 4000);
        
        }
         
      }).catch(error => {
      });
   }
 
   const [getImage, setImage] = useState('');
   const getApiDataImage = () => {
  
       API.get('get_settings')
       .then(response => {
       
         if (response.data.status == '0') 
         {
             setImage('assets/images/logo-old.png');
            
         } 
         else {
           
           setImage(response.data.data.image);
           
         }
       }).catch(error => {
       });
         
     }

 
    return (
        <>
        <Navbar sidelink='home' headerText={'CONFIRMATION'} headerimg={getImage}/>
        <section className="ckeinpgsec">
            <Container fluid>
              <Row>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-lftcnt">
                  <div>
                  </div>
                  <div className="text-center mb-4"><img src="assets/images/svg/check-img.svg" className="img-fluid" alt="Plus"/></div>
                  <p>Please have a seat,</p>
                  <p>a pharmacist will be with you shortly.</p>

                </Col>
                <Col lg={6} md={12} xs={12} className="text-center ckeinpg-rgtcnt cnfrmpcuppg">
                <div class="mb-3" id="bankDetailsMsg"></div>
                  <div className="mb-4 tpcnt-cnpkupcnt">
                   
                     
                      <h4>Name: <span>{name}</span></h4>
                      <p className="fw-bold">#{getCode}</p>
                      <p className="fw-bold">{moment().format('h:mm A')}</p>
                      <p>{moment().format('dddd').toUpperCase()}, {moment().format('MMMM').toUpperCase()} {moment().format('D, YYYY')}</p>
                  </div>
                  <div className="fst-btn"><a onClick={()=> handleChange()}><button type="button" class="btn btn-outline-primary">CONFIRM</button></a></div>
                  <div className="two-btn"><a href="/"><button type="button" class="btn btn-outline-primary">CANCEL</button></a></div>
                </Col>
              </Row>
           </Container>
        </section>


  

        </>
    )
}
export default Confirmationpickupn;