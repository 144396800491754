import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Button, Form, Tab, Nav, NavItem, Card } from 'react-bootstrap';
import './Shop.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Sidebar from "../sidebar";
import API from '../../Constants/Api';
const Shop = () => {
  const [getCategory, setCategory] = useState([]);
  const [getProduct, setProduct] = useState([]);


   useEffect(() => {
   API.get('get_categories')
      .then(response => {
        if (response.data.status === 0) 
        {
        } 
        else {
         
          setCategory(response.data.data);
        }
      }).catch(error => {
      });

      API.get('get_product')
      .then(response => {
        if (response.data.status === 0) 
        {
        } 
        else {
          console.log(response.data.data);
          setProduct(response.data.data);
          
        }
      }).catch(error => {
      });
      getApiDataImage();


      document.title = `Shop | GRUBBRR`;
      document.getElementById('bodywrap').className='shoppgmain';

   },[])  

  const product = (category_id) => {
  
    API.get('get_product_by_category_id/'+ category_id)
    .then(response => {
     console.log(response.data);
      if (response.data.status === '0') 
      {
        setProduct([]);
      } 
      else {
        console.log(response.data.data);
        setProduct(response.data.data);
      
      }
    }).catch(error => {
    });
  }

  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }

  

  
 
 
    return (
        <>
        <Navbar sidelink='shop' headerText={'SHOP'} headerimg={getImage}/>
        {/* variant="pills" */}
        <section className="shoppgsec">
            <Container fluid>
             <Row>
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
               <Row className="mx-0 px-0">
                 <Col xs={3} md={2} lg={1} className="scrl-tpdwn px-0">
                 {getCategory.length > 0 && getCategory.map((item, index) => {
                   return (<Nav  className="flex-column" onClick={()=> product(item.id) }>
                     <Nav.Item className="text-center">
                       <Nav.Link eventKey={0}>
                       <img src={item.image} className="img-fluid" alt="Plus"/>
                       <p>{item.name}</p>
                       </Nav.Link>
                     </Nav.Item>
                     
                   </Nav>)
                })}
                
                 </Col>
                 <Col xs={9} md={10} lg={11} className="scrl-tpdwn bgwhit">
                   <Tab.Content>
                   
                     <Tab.Pane eventKey={0}>
                        <Row>
                        

                        {getProduct.length > 0 && getProduct.map((item, index) => {
                           var url = `Iteamcovid?id=${item.id}`;
                        return (
                          
                           <Col xs={12} md={6} lg={3} >
                           <a href ={url}>
                              <Card>
                              <Card.Img variant="top" src={item.product_image} className="img-fluid w-md-50 w-50" />
                              <Card.Body>
                                <Card.Text>{item.name}</Card.Text>
                                <Card.Text><span>${item.price}</span></Card.Text>
                              </Card.Body>
                            </Card>
                            </a>
                           </Col>)
                          })}
                        </Row>
                     </Tab.Pane>
                     
                   </Tab.Content>
                 </Col>
                 {/* <Row className="sppgbtm-fixxdv">
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-lftcntbox"></Col>
                 <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="sppgbtm-rgtcntbox">
                    <Col xs={12} md={6} lg={4} xl={3}>
                    <div className="txt-cnt d-none">
                       <h4>Total: </h4>
                       <p>$7.25</p>
                    </div>
                    <div className="fst-btn"><a href="javascript:void(0)"><button type="button" class="btn btn-outline-primary">Add to Cart</button></a></div>
                    <div className="two-btn"><a href="javascript:void(0)"><button type="button" class="btn btn-outline-primary">Cancel</button></a></div>
                    </Col>
                 </Col>
                 </Row> */}
               </Row>
             </Tab.Container>
            </Row>
           </Container>
        </section>

        </>
    )
}
export default Shop;