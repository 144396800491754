import { Row, Col, Nav, NavItem} from 'react-bootstrap';
function Sidebar(props) {   
  return (

<Row className="mx-0">
  <Col md={12} className="px-0 sidewrap">
    <img src="assets/images/logo.svg" className="img-fluid" alt="logo" />
    <Nav bsStyle="pills" stacked>
        <NavItem eventKey="first">Tab 1</NavItem>
        <NavItem eventKey="second">Tab 2</NavItem>
      </Nav>
  </Col>
</Row>


  );
}
export default Sidebar;