import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Row, Col, Container, Button, Form, Tab, Nav, NavItem, Card } from 'react-bootstrap';
import './Checkout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import Sidebar from "../sidebar";
import API from '../../Constants/Api';
import $ from "jquery";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';


const Checkout = () => {

  const [oldItems, setOldItems] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [subAmount, setSubAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [getError, setError] = useState('');
  const [getSuccess, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    calculateTotal();
    hide_detail();
    document.title = `Checkout | GRUBBRR`;
    document.getElementById('bodywrap').className = 'favmain';
    getApiDataImage();

  }, [])

  function calculateTotal() 
  {
    

    var total = 0;
    oldItems.map((item) => (total += parseFloat(item.price)).toFixed(2));
    setTotalAmount(total.toFixed(2));
  }

  function back_to_menu() {
    navigate('/home');
  }





  const qty_plus = (id, name, price, product_image, max_qty, original_price) => {
    //localStorage.clear();
    const regex = /^[0-9\b]+$/;
    var val = parseInt($('#qty_text' + id).val());


    if (val != "" || regex.test(val)) {

      if (val < parseInt(max_qty)) {
        var qtyp = val + 1;

        $('#qty_text' + id).val(qtyp);

        // oldItems = JSON.parse(localStorage.getItem('cart')) || [];
        var match = oldItems.find(function (item) {
          return item['id'] === id;
        });
        if (match) {
          match['name'] = name;
          match['qty'] = parseInt(qtyp);
          match['price'] = (parseInt(qtyp) * parseFloat(original_price)).toFixed(2);
          match['image'] = product_image;
        } else {
          var newItem = {
            'id': id,
            'name': name,
            'qty': parseInt(qtyp),
            'price': parseFloat(price),
            'image': product_image,
            'original_price': parseFloat(original_price),
            'max_qty': max_qty,
          };
          oldItems.push(newItem);
        }
        localStorage.setItem('cart', JSON.stringify(oldItems));

        console.log(localStorage.getItem('cart'));

      }
    }
    else {

    }
    calculateTotal();
  };

  const qty_minus = (id, name, price, product_image, max_qty, original_price) => {
    const regex = /^[0-9\b]+$/;
    var val = parseInt($('#qty_text' + id).val());
    if (val != "" || regex.test(val)) {
      if (val > 1) {
        var qtyp = val - 1;
        $('#qty_text' + id).val(qtyp);
        var match = oldItems.find(function (item) {
          return item['id'] === id;
        });
        if (match) {
          match['name'] = name;
          match['qty'] = parseInt(qtyp);
          match['price'] = parseInt(qtyp) * parseFloat(original_price);
          match['image'] = product_image;
        }
        localStorage.setItem('cart', JSON.stringify(oldItems));


      }
      else if (val == 1) {
        var qtyp = val - 1;
        
        oldItems.filter((item) => item.id !== id);

        localStorage.setItem('cart', JSON.stringify(oldItems));

      }
      console.log(localStorage.getItem('cart'));


    }
    else {

    }
    calculateTotal();
  }


 

  const delete_record = (id) => {

    if (window.confirm("Are you sure you want to delete this product ?"))
    { 
        
        oldItems.splice(id,1);
        localStorage.clear();
        setOldItems(oldItems);
        localStorage.setItem('cart', JSON.stringify(oldItems));
        console.log(oldItems);
        calculateTotal();
        hide_detail();
    }
  }

  const hide_detail = () =>
  {
      if(oldItems.length == 0)
      {
        navigate('/shop');
        $('.iteampgsec').css('display','none');
        $('.cart').css('display','none');
        $('.empty_data').css('display','block');
      }
  }

  const clear_cart = () => {
    localStorage.clear();
    navigate('/shop');
  }
  

  const pay =() => {
     

      let orderFormData = new FormData();
      orderFormData.append('name', name);
      orderFormData.append('mobile', mobile);
      orderFormData.append('total', totalAmount);
      orderFormData.append('cart', JSON.stringify(oldItems));


    API.post('add_order', orderFormData)
      .then(response => {

        console.log(response);
        if (response.data.status == '0') 
        {
          setError(response.data.message);
          $('#bankDetailsMsg').html('<span class="text-danger">' + response.data.message + '</span>');
        } 
        else {
          $('#bankDetailsMsg').html('<span class="text-success">' + response.data.message + '</span>');
          setSuccess(response.data.message);
          handleShow();
        }
         
      }).catch(error => {
      });
  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.localStorage.clear()
    setOldItems([]);
    calculateTotal();
    hide_detail();
    

    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
    
  };
  const handleShow = () => setShow(true);

  const [getImage, setImage] = useState('');
  const getApiDataImage = () => {
 
      API.get('get_settings')
      .then(response => {
      
        if (response.data.status == '0') 
        {
            setImage('assets/images/logo-old.png');
           
        } 
        else {
          
          setImage(response.data.data.image);
          
        }
      }).catch(error => {
      });
        
    }

  return (
    
    <>
      <Navbar sidelink='checkout' headerText='Checkout' headerimg={getImage} />
     
     
      <section className="iteampgsec" >

        <Table responsive >
          <tbody>
            {

              oldItems.length > 0 && oldItems.map((item, index) => {

                return (

                  <tr>


                    <td className='text-center'><img src={item.image} width={100} /></td>
                    <td className='text-center'><div>{item.name}</div></td>


                    <td className='text-center'>
                      <div class="stepper-input">
                        <button class="stepper-input__button" id='qtyminus' onClick={() => qty_minus(item.id, item.name, item.price, item.image, item.max_qty, item.original_price)} >-</button>

                        <div class="stepper-input__content">
                          <input type="text" class="stepper-input__input" id={`qty_text${item.id}`} placeholder="1" value={item.qty} readOnly="readOnly" />
                        </div>

                        <button class="stepper-input__button" onClick={() => qty_plus(item.id, item.name, item.price, item.image, item.max_qty, item.original_price)} id='qty_plus'>+</button>
                      </div>
                    </td>
                    <td id={`price${item.id}`} className='text-center'>$ {Number(item.price).toFixed(2)}</td>
                    <td id={`delete${item.id}`} className='text-center'>
                      <div>
                        <img width={30} alt={item.name} onClick={() => delete_record(index)} src="assets/images/delete.png" />
                      </div>

                    </td>
                  </tr>


                )

              })
            }
          </tbody>
        </Table>

      </section>

      <section className="cart">
        <Container fluid className='px-0'>
          <Row className="sppgbtm-fixxdv">

          </Row>
        </Container>


        <div className='container-fluid'>
          <div className='row'>
          <div className='col-md-5 col-sm-12'>
            
            <h5 className='text-center mt-2'><b>Personal Detail</b></h5>
            <div class="mt-2 text-center" id="bankDetailsMsg"></div>
            <Form className="text-center">
              <Form.Group className="mb-3 fltng-inpt" controlId="name">
                <Form.Control type="name" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required id="name"  />
                <Form.Label>Name</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3 fltng-inpt" controlId="number">
                <Form.Control type="number" value={mobile} onChange={e => setMobile(e.target.value)}  id="number" placeholder="Phone Number" required />
                <Form.Label>Phone Number</Form.Label>
              </Form.Group>
            </Form>
          </div>
            <div className='col-md-3 col-sm-12 align-self-center'>
              <div className='row mt-4'>
                <div className='col-12'>
                  <div className="txt-cnt">
                    <h4>SubTotal: </h4>
                    <p>$ {totalAmount}</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className="txt-cnt">
                    <h4>Total Tax: </h4>
                    <p>$ { ((totalAmount * 9 ) / 100).toFixed(2) }</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className="txt-cnt">
                    <h4>Total: </h4>
                    <p>$ { (parseFloat(((totalAmount * 9 ) / 100).toFixed(2)) + parseFloat(totalAmount)).toFixed(2)}</p>
                  </div>
                </div>
              </div>




            </div>
            

            <div className='col-md-2 col-sm-12 align-self-center'>

              <div className='right_btn'>
                <button className='btn btn1' onClick={() => back_to_menu()}>Back To Menu</button>
                <button className='btn btn2' onClick={() => clear_cart()}>Cancel Order</button>
              </div>


            </div>
            <div className='col-md-2 col-sm-12 align-self-center'>


              <div className='left_btn'>
                <button className='btn btn1' onClick={() => pay()}>Pay</button>

              </div>


            </div>

          </div>
        </div>

        <Modal show={show} onHide={() => handleClose()} centered backdrop={'static'} >
         
          <Modal.Body>
            <Row>
              <Col lg={12} xl={12} xs={12}>
              <div className="text-center m-4">
                <img src="assets/images/svg/check-img.svg" className="img-fluid" alt="Plus"/>
              
              
              </div>

              <div className="text-center m-4">
               
                  <h4><b>Thank you for shopping with us</b></h4>
               </div>

              <div className="text-center m-4">
               
              <Button style={{ 'width': '100%',
                                          'border': '0px solid transparent',
                                          'padding': '5px 30px',
                                          'background':'#335e90',
                                          'font-size': '36px',
                                          'font-weight': '700',
                                          'margin-bottom': '10px',
                                          'text-transform': 'uppercase'}}  onClick={() => handleClose()}>
                  Ok
                </Button>
              
              </div>
                
            </Col>
            </Row>
            </Modal.Body>
            
         
        </Modal>
      </section>
      
      <section className='empty_data' style={{display:'none'}}>
        <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                  <div  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    }}>
                    <h2>Cart is empty</h2>
                    
                  </div>
                  <div  style={{
                    position: "absolute",
                    top: "60%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    }}>
                    
                    <input type="button" onClick={() => back_to_menu()} className='btn btn-primary text-center' name="Home" value="Back to home" style={{ 'width': '100%',
                                          'border': '0px solid transparent',
                                          'padding': '5px 30px',
                                          'background':'#335e90',
                                          'font-size': '18px',
                                          'font-weight': '700',
                                          'margin-bottom': '10px',
                                          'text-transform': 'uppercase'}} / >
                  </div>
              </div>
            </div>
        </div>
            
      </section>


    </>
  
  )
}
export default Checkout;