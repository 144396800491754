import axios from 'axios';
import Constants from "../Constants/Constants";
export default axios.create({
    baseURL: Constants.BASE_URL,
    timeout: 500000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "access-control-allow-origin": "*",
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
});